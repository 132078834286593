<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card v-if="data">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col md="1">
              <div class="d-flex justify-content-center align-items-center">
                <b-img fluid :src="data.cover"></b-img>
              </div>
            </b-col>
            <b-col class="my-auto" md="9">
              <h4>
                <span style="color:#7367F0;"
                  >{{ $t("Add_Product.Name") }} :</span
                >
                {{ name }}
              </h4>
              <br />
              <h4>
                <span style="color:#7367F0;"
                  >{{ $t("Add_Product.SKU") }} رمز :
                </span>
                {{ data.sku }}
              </h4>
              <br />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <validation-observer ref="simpleRules">
            <h2>{{ $t("Add_Product.Images") }}</h2>
            <b-row>
              <b-col sm="12">
                <div>
                  <b-form
                    ref="imagesForm"
                    :style="{ height: trHeight }"
                    class="repeater-form"
                    @submit.prevent="repeateAgain"
                    enctype="multipart/form-data"
                  >
                    <!-- Row Loop -->
                    <b-row
                      v-for="(item, index) in itemData.images"
                      :id="item.id"
                      :key="item.id"
                      ref="row"
                    >
                      <!-- Image Reapeted -->
                      <b-col md="4">
                        <b-form-group
                          :label="$t('Add_Product.Image')"
                          label-for="image"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="image"
                            rules="required"
                          >
                            <b-form-file
                              type="file"
                              :name="'image' + index"
                              v-model="upImage[index].file"
                              @change="uploadImage($event, index)"
                              :state="errors.length > 0 ? false : null"
                              :id="'image' + index"
                              accept="image/*"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col lg="2" md="3" class="mb-50">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeItem(index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>{{ $t("Add_Product.Delete") }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-col>
              <!-- add new button -->
              <b-col sm="2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  block
                  @click="repeateAgain"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{ $t("Add_Product.Add_New") }}</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
              <!-- submit button -->
              <b-col>
                <b-button
                  size="lg"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  {{ $t("Add_Product.Submit") }}
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import { required, integer } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";

export default {  
  mixins: [heightTransition],
  data() {
    return {
      upImage: [
        {
          file: [],
        },
      ],
      showDismissibleAlert: false,
      errors_back: [],
      test_result: "",
      itemData: {
        images: [
          {
            name: "",
          },
        ],
      },
      required,
      data: null,
      name: null,
    };
  },
  created() {
    
    this.id = this.$route.params.id;
    this.getVariantData(this.id);
  },
  methods: {
    getVariantData(id) {
      axios
        .get("variants/" + id + "/show")
        .then((result) => {
          const data = result.data.data;
          this.data = data;

          this.name = data.translation[0].name;

          // this.vendorCommissionRate = data.product.vendor.commission.rate;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          for (let img in this.itemData.images) {
            if (this.itemData.images[img].name.includes(",")) {
              this.itemData.images[img].name = this.itemData.images[img].name
                .split(",")
                .map((item) => item.trim());
              this.itemData.images[img].name = this.itemData.images[
                img
              ].name[1];
            }
          }
          axios
            .post("variants/" + this.id + "/media/store", this.itemData)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    uploadImage(event, index) {
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        this.itemData.images[index].name = [];
        return;
      } else {
        this.createImage(files[0], index);
      }
    },
    createImage(file, ind) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.itemData.images[ind].name = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    repeateAgain() {
      this.refheightForm = this.$refs.imagesForm;
      this.itemData.images.push({
        //  id: this.nextTodoId += this.nextTodoId,
      });
      this.upImage.push({
        //  id: this.nextTodoId += this.nextTodoId,
      });
    },
    removeItem(index) {
      if (this.itemData.images.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        this.itemData.images.splice(index, 1);
        this.upImage.splice(index, 1);
      }
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss"; // REPETED FORM
.repeater-form {
  transition: 0.35s height;
}
</style>
